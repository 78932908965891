import clue from '@/router/modules/opportunity/clue'
import biddingForecast from '@/router/modules/opportunity/biddingForecast'
import biddingProject from '@/router/modules/opportunity/biddingProject'
import project from '@/router/modules/opportunity/project'
import overseaProject from '@/router/modules/opportunity/overseaProject'
import customerInfo from '@/router/modules/opportunity/customerInfo'
import designInstitute from '@/router/modules/opportunity/designInstitute'
import companies from '@/router/modules/opportunity/companies'

export default {
    meta: {
        i18n: 'route.opportunity.name',
        icon: 'ic:outline-business',
        auth: ['opportunity']
    },
    children: [
        clue,
        biddingForecast,
        biddingProject,
        project,
        overseaProject,
        customerInfo,
        designInstitute,
        companies
    ]
}

const Layout = () => import('@/layout/index.vue')

export default {
    path: '/opportunity/companies',
    component: Layout,
    redirect: { name: 'opportunityCompaniesList' },
    meta: {
        i18n: 'route.opportunity.companies.name',
        icon: 'ri:building-line',
        auth: ['opportunity_enterprise']
    },
    children: [
        {
            path: 'list',
            name: 'opportunityCompaniesList',
            component: () => import('@/views/opportunity/companies/list.vue'),
            meta: {
                i18n: 'route.opportunity.companies.list',
                cache: true,
                auth: ['opportunity_enterprise_list']
            }
        },
        {
            path: 'add',
            name: 'opportunityCompaniesAdd',
            component: () => import('@/views/opportunity/companies/form.vue'),
            meta: {
                i18n: 'route.opportunity.companies.add',
                auth: ['opportunity_enterprise_add']
            }
        },
        {
            path: 'update/:id',
            name: 'opportunityCompaniesUpdate',
            component: () => import('@/views/opportunity/companies/form.vue'),
            meta: {
                i18n: 'route.opportunity.companies.update',
                sidebar: false,
                activeMenu: '/opportunity/companies/list',
                auth: ['opportunity_enterprise_update']
            }
        },
        {
            path: 'view/:id',
            name: 'opportunityCompaniesView',
            component: () => import('@/views/opportunity/companies/view.vue'),
            meta: {
                i18n: 'route.opportunity.companies.view',
                sidebar: false,
                activeMenu: '/opportunity/companies/list',
                cache: true,
                auth: ['opportunity_enterprise_view']
            }
        },
        {
            path: 'customer/add',
            name: 'opportunityCompaniesCustomerFrom',
            component: () => import('@/views/opportunity/companies/customer/form.vue'),
            meta: {
                i18n: 'route.opportunity.companies.customers',
                sidebar: false,
                activeMenu: '/opportunity/companies/customer/list',
                cache: false,
                auth: ['opportunity_enterprise_customer_add']
            }
        },
        {
            path: 'customer/update/:id',
            name: 'opportunityCompaniesCustomerUpdate',
            component: () => import('@/views/opportunity/companies/customer/form.vue'),
            meta: {
                i18n: 'route.opportunity.companies.customers',
                sidebar: false,
                activeMenu: '/opportunity/companies/customer/list',
                cache: false,
                auth: ['opportunity_enterprise_customer_update']
            }
        },
        {
            path: 'customer/view/:id',
            name: 'opportunityCompaniesCustomerView',
            component: () => import('@/views/opportunity/companies/customer/view.vue'),
            meta: {
                i18n: 'route.opportunity.companies.customers',
                sidebar: false,
                activeMenu: '/opportunity/companies/customer/list',
                cache: false,
                auth: ['opportunity_enterprise_customer_view']
            }
        },
        {
            path: 'customer/:id/approve/:workId',
            name: 'opportunityCompaniesCustomerApproveView',
            component: () => import('@/views/opportunity/companies/customer/approve/view.vue'),
            meta: {
                i18n: 'route.opportunity.companies.customers',
                sidebar: false,
                activeMenu: '/opportunity/companies/customer/list',
                cache: false
            }
        },
        {
            path: 'customer/list',
            name: 'opportunityCompaniesCustomerList',
            component: () => import('@/views/opportunity/companies/customer/list.vue'),
            meta: {
                i18n: 'route.opportunity.companies.customers',
                activeMenu: '/opportunity/companies/customer/list',
                cache: true,
                auth: ['opportunity_enterprise_customer_list']
            }
        }
    ]
}


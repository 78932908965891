import factory from './factory'

export default {
    meta: {
        i18n: 'route.factory.name',
        icon: 'ri:file-word-2-line',
        auth: ['factory']
    },
    children: [
        factory
    ]
}

import patent from './patent'
import material from './material'

export default {
    meta: {
        title: '情报',
        icon: 'mdi:certificate',
        auth: ['intelligence']
    },
    children: [
        patent,
        material
    ]
}


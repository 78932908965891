const Layout = () => import('@/layout/index.vue')

export default {
    path: '/intelligence/patent',
    component: Layout,
    redirect: { name: 'patentList' },
    meta: {
        title: '专利信息',
        icon: 'mdi:shield-check',
        auth: ['intelligence_patent'],
        breadcrumb: false
    },
    children: [
        {
            path: 'list',
            name: 'patentList',
            component: () => import('@/views/intelligence/patent/list.vue'),
            meta: {
                title: '专利列表',
                cache: true,
                auth: ['intelligence_patent_list']
            }
        },
        {
            path: 'view/:id',
            name: 'patentView',
            component: () => import('@/views/intelligence/patent/view.vue'),
            meta: {
                title: '专利信息',
                sidebar: false,
                activeMenu: '/intelligence/patent/list',
                cache: true,
                auth: ['intelligence_patent_view']
            }
        }
    ]
}

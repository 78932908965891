const Layout = () => import('@/layout/index.vue')

export default {
    path: '/factory',
    component: Layout,
    redirect: { name: 'productionDetail' },
    meta: {
      i18n: 'route.factory.list',
      icon: 'ri:user-settings-line',
      breadcrumb: false
    },
    children: [
      {
        path: 'production',
        name: 'productionDetail',
        component: () => import('@/views/factory/production.vue'),
        meta: {
          i18n: 'route.factory.production',
        //   title: 'route.factory.production',
          cache: true,
          auth: ['factory_dv_update']
        }
      },

    ]
  }
